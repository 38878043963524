<template>
  <v-card>
    <v-row justify="center">
      <v-col cols="12" sm="6" md="4">
        <RoomAutoComplete v-model="room"></RoomAutoComplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <DataTableSSR
            :api-end-point="`/payments/report/payment-summary?room=${room}`"
            :headers="headers"
            :instantLoad="true"
          >
            <template
              v-for="(month, index) in months"
              #[`item.${month}`]="{ item }"
            >
              <a :key="index"
                >{{ item[month + "_paid"] }}/{{ item[month + "_fee"] }}</a
              >
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                link
                icon
                :to="`/organization/payment/report/individual/${item.user.id}`"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
          </DataTableSSR>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import DataTableSSR from "@/components/global/DataTableSSR.vue";
import RoomAutoComplete from "../../../components/RoomAutoComplete.vue";
export default {
  components: { DataTableSSR, RoomAutoComplete },
  data() {
    return {
      months: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],
      room: "",
      payments: [],
      headers: [{ text: "User", value: "user.full_name" }],
    };
  },
  created() {
    this.headers = [
      ...this.headers,
      ...this.months.map((m) => ({
        text: m.toUpperCase(),
        value: m,
      })),
      {
        text: "Actions",
        value: "actions",
      },
    ];
  },
  methods: {},
  computed: {},
};
</script>

<style>
</style>
